import { graphql } from 'gatsby';
import { ModalRoutingContext } from 'gatsby-plugin-modal-routing-3';
import { RichText } from 'prismic-reactjs';
import PropTypes from 'prop-types';
import React from 'react';

import Layout from '../components/Layout';
import * as layoutStyles from '../components/Layout.module.scss';
import ModalCloseButton from '../components/ModalCloseButton';
import TextBlock from '../components/TextBlock';

import * as styles from './about.module.scss';

const About = ({ data }) => {
  if (!data) return null;
  const document = data.allPrismicAbout.edges[0].node.data;

  return (
    <ModalRoutingContext.Consumer>
      {({ modal, closeTo }) => (
        <Layout>
          <section className={`${styles.layout} ${!modal ? styles.scroll : ''} ${layoutStyles.wrapper}`}>
            <section className={styles.textBlocks}>
              {
                document.body.map((slice, index) => (
                  <TextBlock key={index}>
                    <h1>{slice.primary.title.text}</h1>
                    <RichText render={slice.primary.text.raw}></RichText>
                  </TextBlock>
                ))
              }
            </section>

            <section className={styles.pinnedBlocks}>
              <TextBlock>
                <h1>Clients:</h1>
                <RichText render={document.clients.raw}></RichText>
              </TextBlock>

              <TextBlock>
                <h1>Contact:</h1>
                <RichText render={document.contact.raw}></RichText>
              </TextBlock>
            </section>
          </section>

          <ModalCloseButton to={closeTo || '/'}></ModalCloseButton>
        </Layout>
      )}
    </ModalRoutingContext.Consumer>
  );
};

About.propTypes = {
  data: PropTypes.object,
};

export default About;

export const query = graphql`
    query About {
      allPrismicAbout {
        edges {
          node {
            data {
              clients {
                raw
              }
              contact {
                raw
              }
              body {
                ... on PrismicAboutBodyTextBlock {
                  id
                  slice_type
                  primary {
                    text {
                      raw
                    }
                    title {
                      text
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
`;
