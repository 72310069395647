import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

import * as styles from './ModalCloseButton.module.scss';

const ModalCloseButton = ({ to }) => {
  return <Link className={styles.root} to={to}>
    <span></span>
  </Link>;
};

ModalCloseButton.propTypes = {
  to: PropTypes.string.isRequired,
};

export default ModalCloseButton;
